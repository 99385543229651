body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --color-primary: rgb(77, 164, 255);
  --color-primary-light: rgb(77, 164, 255, 0.5);
  --color-secondary: rgb(255, 81, 81);
  --color-secondary-light: rgb(255, 81, 81, 0.5);
  --color-tertiary: rgb(0, 255, 128);
  --color-tertiary-light: rgb(0, 255, 128, 0.5);
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-tertiary {
  color: var(--color-tertiary);
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.bg-tertiary {
  background-color: var(--color-tertiary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.question-card {
  margin-bottom: 1rem;
  box-shadow: 8px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.feedback-alert {
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-wrap: break-word;
  overflow: scroll;
  height: max-content;
}

.feedback-alert-container {
  padding: 0;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-basis: 80%;
}

.feedback-button {
  height: 50px;
}

.check-answer-button,
.retry-answer-button,
.continue-button {
  height: 50px;
  background-color: var(--color-cyan-secondary);
  color: white;
  width: 100%;
  font-size: 1rem !important;
}

.check-and-retry-container,
.check-and-continue-container {
  padding: 0;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-basis: 20%;

}

.MuiAlert-message {
  font-size: 1rem !important;
  text-align: left !important;
}

.feedback-section {
  display: flex;
  column-gap: 2rem;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.finish-screen {
  min-height: 300px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 8px;
}

.fade-in {
  animation: fadeIn ease 1s;
}

.fade-out {
  animation: fadeOut ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* animation for question transitions */
/* Slide-in animation for entering new question */
.slide-in {
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

/* Slide-out animation for exiting old question */
.slide-out {
  animation: slideOut 0.5s forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

/* end animation for question transitions */