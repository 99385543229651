.topics {
    margin-top: 2rem;
    text-align: center;

    & .scrolling-words-container {
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    & .scrolling-words-box {
        height: 3rem;
        margin: auto;
        overflow: hidden;

        & ul {
            margin: 0 0.625rem;
            padding: 0;
            overflow: hidden;

            &>li {
                display: flex;
                align-items: center;
                justify-content: left;
                font-size: 2rem;
                height: 2em;
                list-style: none;
            }
        }
    }
}