body {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.025) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.025) 1px, transparent 1px);
    background-size: 25px 25px;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.login-container {
    /*min-height: 80vh;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 5%;
}

