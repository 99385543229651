table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

pre {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
}

code {
    font-family: 'Courier New',
        Courier, monospace;
    background-color: #f4f4f4;
}